import BlogPostContainer from "./BlogPostContainer";

const Schweden = () => {
    const blogPosts = [
        {
            date: "31.01.2025",
            title: "8. Tag",
            content: (
                <>
                    Hallo zusammen
                    <br/><br/>
                    Nach dem Frühstück sind wir nach Malå gefahren. Dort konnten wir eine Tour mit einem Rentierhirten unternehmen.
                    Er hat uns einiges über das Leben mit den Tieren erzählt und wir konnten eine Herde Jungtiere hautnah erleben.
                    Nach ein paar Leckerchen verteilen und Fotos machen, ging es in eine Tipihütte, wo wir leckere Pitas gegessen haben.
                    <br/><br/>
                    Somit ist der letzte Abend in Sandsjögarden fertig, morgen geht es für eine Nacht nach Stockholm,
                    bevor wir am Sonntag zurück in der Schweiz sind.
                    <br/><br/>
                    Somit war es das für diese Reise, wir hören/lesen uns bei meinem nächsten Abenteuer.
                    <br/><br/>
                    Eure Rentierstreichlerin
                </>
            ),
            images: [
                "/images/08_Schweden/8.1.jpeg",
                "/images/08_Schweden/8.2.jpeg",
                "/images/08_Schweden/8.3.jpeg",
                "/images/08_Schweden/8.4.jpeg",
                "/images/08_Schweden/8.5.jpeg",
                "/images/08_Schweden/8.6.jpeg",
            ]
        },
        {
            date: "30.01.2025",
            title: "7. Tag",
            content: (
                <>
                    Guten Abend,
                    <br/><br/>
                    Heute nach dem Frühstück und einigen Geschichten von Caro haben wir uns entschieden, dass wir mit einigen der Hunde
                    spazieren gehen wollen. Nach dem Mittag haben wir uns also mit Caro getroffen und sind mit drei Hunden,
                    einen für Michi, einen für Ivana und einen für mich, losgegangen. Amarok, Storm und Czita haben sich riesig darauf gefreut.
                    <br/><br/>
                    Mein Hund, Amarok ist ein Senior Leithund aus Caros Rennteam. Er ist mittlerweile schon 13.5 Jährig,
                    hat aber trotzdem noch einiges an Energie. Er ist ein Malamute/Grönlandhund Mix, wobei er mehr nach einem
                    Malamute aussieht und gemäss Caro auch den Dickkopf eines Malamutes hat.
                    <br/><br/>
                    Nun sind wir nach einem kurzen Abstecher in ein ICA im nächsten Dorf zurück und treffen uns später fürs
                    Znacht.
                    <br/><br/>
                    Damit sind wir bereits beim Ende von diesem Tag angekommen, wir hören uns morgen wieder.
                    <br/><br/>
                    Eure Hundeflüsterin
                </>
            ),
            images: [
                "/images/08_Schweden/7.1.jpeg",
                "/images/08_Schweden/7.2.jpeg",
                "/images/08_Schweden/7.3.jpeg",
                "/images/08_Schweden/7.4.jpeg",
            ]
        },
        {
            date: "29.01.2025",
            title: "6. Tag",
            content: (
                <>
                    Auf in ein neues Abenteuer...
                    <br/><br/>
                    Heute ging es Hundeschlitten fahren. Zuerst gab es eine Einweisung, danach haben wir uns zu zweit auf
                    die Schlitten verteilt.
                    <br/>
                    Es war mega schön, ziemlich lautlos durch die Wälder zu fahren. Nur wenn wir angehalten haben, hat der
                    Lärm von Hundegebell angefangen.
                    <br/><br/>
                    Nach fast drei Stunden fahrt sind wir ziemlich erschöpft angekommen.
                    <br/><br/>
                    Nach einem feinen Znacht ging es sehr früh ins Bett.
                    <br/><br/>
                    Eure Schlittenfahrer
                </>
            ),
            images: [
                "/images/08_Schweden/6.1.jpeg",
                "/images/08_Schweden/6.2.jpeg",
                "/images/08_Schweden/6.3.jpeg",
                "/images/08_Schweden/6.4.jpeg",
            ]
        },
        {
            date: "28.01.2025",
            title: "5. Tag",
            content: (
                <>
                    Heute sind wir es ruhig angegangen. Nach dem Frühstück etwas ausruhen und am Nachmittag sind wir spazieren gegangen.
                    <br/><br/>
                    Am Abend hatten wir Glück und haben Nordlichter gesehen.
                </>
            ),
            images: [
                "/images/08_Schweden/5.1.jpeg",
                "/images/08_Schweden/5.2.jpeg",
                "/images/08_Schweden/5.3.jpeg",
                "/images/08_Schweden/5.4.jpeg",
                "/images/08_Schweden/5.5.jpeg",
            ]
        },
        {
            date: "27.01.2025",
            title: "4. Tag",
            content: (
                <>
                    Heute sind wir es ruhig angegangen.
                    <br/>
                    Nach einem gemütlichen Frühstück, haben wir uns für einen Ausflug in das nächst grössere Dorf entschieden.
                    <br/>
                    Nach ungefähr einer halben Stunde Autofahrt sind wir in Storuman angekommen. Dort haben wir den Aussichtspunkt besucht.
                    Nachdem ging es für uns ins örtliche Kaffee.
                    <br/><br/>
                    Nach der Heimkehr haben wir uns in die Hütten zurückgezogen und einfach nichts gemacht. Nach dem Abendessen,
                    haben wir eine Runde Nordlichtjagt unternommen... leider ohne Erfolg.
                    <br/><br/>
                    Morgen soll es wieder schneien, mal schauen was wir unternehmen.
                    <br/><br/>
                    Häbet e schöne Obe und bis morn
                    <br/>
                    Eure Nordlichtjägerin
                </>
            ),
            images: [
                "/images/08_Schweden/4.1.jpeg",
                "/images/08_Schweden/4.2.jpeg",
            ]
        },
        {
            date: "26.01.2025",
            title: "3. Tag",
            content: (
                <>
                    Guten Abend und herzlich Willkommen zu einer neuen Folge "was ist heute passiert"
                    <br/><br/>
                    Heute sind wir in der Lodge Frühstücken gegangen. Nachdem wir die ware Kleidung montiert hatten, ging es
                    zu den Huskyzwinger. Nach der Hundeverabschiedung ging es auf einen kurzen Spaziergang am See entlang.
                    <br/><br/>
                    Um 13.30 Uhr haben wir uns dann mit Caro getroffen um unsere Schneemobil Tour zu starte. Nach der Einkleidung
                    gab es die Theoretische Einführung.
                    <br/><br/>
                    Nach zwei sehr coolen Stunden Fahrt sind wir einigermassen Müde zurück in den Hütten. Abendessen gab es
                    dieses Mal in der Lodge.
                    <br/><br/>
                    Eure Snowmobilefahrer
                </>
            ),
            images: [
                "/images/08_Schweden/3.1.jpeg",
                "/images/08_Schweden/3.2.jpeg",
                "/images/08_Schweden/3.3.jpeg",
                "/images/08_Schweden/3.4.jpeg",
                "/images/08_Schweden/3.5.jpeg",
                "/images/08_Schweden/3.6.jpeg",
            ]
        },
        {
            date: "25.01.2025",
            title: "2. Tag",
            content: (
                <>
                    Hej
                    <br/><br/>
                    Ein früher Flug später sind wir in Luleå angekommen. Nach erfolgreicher Suche nach unserem Mietwagen
                    fuhren wir los Richtung unseres Zuhauses für diese Woche. Leider ist Blattnicksele nicht ganz um die Ecke,
                    aber Entfernung ist in Schweden normal. Nach einer etwa 3 Stündigen Fahrt, haben wir einen Zwischenhalt
                    zum Einkaufen gemacht. Danach ging es nochmals ungefähr eine halbe Stunde Richtung Sandsjögarden.
                    <br/><br/>
                    Nach Einzug in der Hütte und einer kleinen Verschnaufpause haben wir uns für einen kurten Entdeckungsspaziergang
                    entschieden.
                    <br/><br/>
                    Zur Stärkung gab es Spaghetti und nach ein paar runden Spielen ging es direkt in die eigene Hütte.
                    <br/><br/>
                    Morgen gehts für uns auf ein rasantes Abenteuer. Davon erzähle ich euch morgen mehr.
                    <br/><br/>
                    Gute Nacht und bis morgen
                    <br/><br/>
                    Die Schneespaziererin
                </>
            ),
            images: [
                "/images/08_Schweden/2.1.jpeg",
                "/images/08_Schweden/2.2.jpeg",
                "/images/08_Schweden/2.3.jpeg",
                "/images/08_Schweden/2.4.jpeg",
                "/images/08_Schweden/2.5.jpeg",
                "/images/08_Schweden/2.6.jpeg",
            ]
        },
        {
            date: "24.01.2025",
            title: "1. Tag",
            content: (
                <>
                    Hallihallo zu einem neuen Tagebuch... diesen Mal für SCHWEDEN
                    <br/><br/>
                    Freitag 24.01.2025
                    <br/>
                    05:00 Uhr: Abfahrt nach Grenchen
                    <br/>
                    05:38 Uhr: Bus nach Grenchen Bahnhof
                    <br/>
                    Zug: Grenchen nach Zürich Flughafen
                    <br/>
                    07:35 Uhr: Check-in 3 und Security-Check
                    <br/>
                    5x UNO
                    <br/>
                    laufen zum Gate
                    <br/>
                    Boarding
                    <br/><br/>
                    Soviel zu unserer Reise nach Stockholm. Nach Ankunft im Hotel direkt am Flughafen ging es mit dem Zug
                    Richtung Stockholm Zentrum. Erster Stopp "Hardrock Cafe", Checklistenpunkt T-Shirt abgeschlossen.
                    Nach einem Spaziergang weiter Richtung Stadtkern und Besuch einiger Shops gab es ein feines Znacht.
                    <br/><br/>
                    Morgen geht es wieder Früh los, deshalb heute bei Zeiten ins Bett.
                    <br/><br/>
                    Tschüssi und ich melde mich morgen wieder, nach unserer Ankunft in Sandsjögarden.
                    <br/><br/>
                    Eure AufEinemNeuenAbenteurer
                </>
            ),
            images: [
                "/images/08_Schweden/1.jpeg",
                "/images/08_Schweden/2.jpeg",
                "/images/08_Schweden/3.jpeg",
                "/images/08_Schweden/4.jpeg",
                "/images/08_Schweden/5.jpeg",
                "/images/08_Schweden/6.jpeg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Schweden</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Schweden;